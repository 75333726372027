






















































































































































































































































































































































































































































































































































































































































































































.coursePage {
  .ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 20rem;
    font-size: 14px;
  }

  .courseIcon {
    width: 14px;
    height: 14px;
    margin-right: 5px;
    display: inline-block;
    background: url("../../assets/courseIcon.png") no-repeat;
    background-size: cover;
  }

  .paperIcon {
    width: 14px;
    height: 14px;
    margin-right: 5px;
    display: inline-block;
    background: url("../../assets/paperIcon.png") no-repeat;
    background-size: cover;
  }

  .nullIcon {
    width: 14px;
    height: 14px;
    margin-right: 5px;
    display: inline-block;
  }
}
